import { render, staticRenderFns } from "./CPA.vue?vue&type=template&id=0dd20c97&scoped=true&"
import script from "./CPA.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CPA.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CPA.vue?vue&type=style&index=0&id=0dd20c97&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd20c97",
  null
  
)

export default component.exports