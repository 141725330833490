import { ref } from 'vue'
import { Widget } from 'Widget/class/Widget'
import { CONVERSION_ALERT_DURATION_MAX } from 'Widget/constants/delays'
import { IPusherConversionAlert } from 'Widget/types'
import { preloadVideo } from 'Widget/utils/preloadUtils'

export class ConvertionAlert {
  widget: Widget

  isShowing = ref(false)
  currentAlert = ref<IPusherConversionAlert | null>(null)
  alertTimeout = ref<NodeJS.Timeout | null>(null)

  constructor (widget: Widget) {
    this.widget = widget
  }

  async showAlert (alert: IPusherConversionAlert) {
    if (this.widget.creativesManager.showWrapper.value || this.isShowing.value) return

    await preloadVideo(alert.animation.path)

    this.currentAlert.value = alert
    this.isShowing.value = true

    this.alertTimeout.value = setTimeout(() => {
      this.hideAlert()
    }, CONVERSION_ALERT_DURATION_MAX)
  }

  hideAlert () {
    this.currentAlert.value = null
    this.isShowing.value = false
  }

  clearAlertTimeout () {
    if (this.alertTimeout.value) {
      clearTimeout(this.alertTimeout.value)
      this.alertTimeout.value = null
    }
  }
}
