import { ref } from 'vue'
import { Freemium } from 'views/Widget/class/Freemium'
import { ConvertionAlert } from 'Widget/class/ConvertionAlert'
import { CreativesLoop } from 'Widget/class/CreativesLoop'
import { CreativesManager } from 'Widget/class/CreativesManager'
import { Preparer } from 'Widget/class/Preparer'
import { Pusher } from 'Widget/class/Pusher'
import { RequestLoop } from 'Widget/class/RequestLoop'
import { Screenshot } from 'Widget/class/Screenshot'
import { defaultWidget } from 'Widget/constants/default-widget'
import { BS_CHECK_BEFORE_REQUEST, PRIMAL_TIMER_TIMEOUT } from 'Widget/constants/delays'
import { wait } from 'Widget/utils/wait'

import { vm } from '@/main'
import { api } from 'lib/api'
import { Logger } from 'lib/helpers/handleLog'
import { IStreamInfo } from 'lib/types/creative'
import { AdvertisingMode, IWidget } from 'lib/types/widget'

export class Widget {
  slug: string
  pusher: Pusher
  version: string | null = null
  data = ref<IWidget>(defaultWidget)
  isLoaded = ref(false)
  isDebug = ref(false)
  requestLoop = new RequestLoop(this)
  creativesLoop = new CreativesLoop(this)
  creativesManager = new CreativesManager(this)
  freemium = new Freemium(this)
  preparer = new Preparer(this)
  conversionAlert = new ConvertionAlert(this)
  screenshot = new Screenshot(this)
  streamInfo: IStreamInfo | null = null

  constructor (slug = '') {
    this.slug = slug
    if (slug) {
      this.init()
    }
    this.pusher = new Pusher(this)
  }

  get isManual () {
    return this.data.value.advertising.mode === AdvertisingMode.MANUAL
  }

  get isStreamerDeactivated () {
    return this.data.value.streamer?.deleted || this.data.value.streamer?.deleted_request
  }

  get widgetResolutionIsCorrect () {
    if (window.innerWidth < 1200 || window.innerHeight < 700) {
      return false
    }
    return true
  }

  async init (): Promise<void> {
    Logger.debug('WIDGET INIT')

    // @ts-ignore
    window?.Beamer?.destroy()

    this.setIsDebug()
    await this.activateWidget()
    await this.fetchWidget()
    // this.configureRollbar(this.data.value)

    if (this.isStreamerDeactivated) {
      return
    }

    this.pusher.init()
    this.isLoaded.value = true
    this.freemium.init()
    this.creativesLoop.init()

    await wait(PRIMAL_TIMER_TIMEOUT - BS_CHECK_BEFORE_REQUEST)

    this.requestLoop.init()
    this.screenshot.enable()
  }

  setIsDebug () {
    const url = new URL(window.location.href)
    this.isDebug.value = !!url.searchParams.get('debug')
  }

  async activateWidget (): Promise<void> {
    if (!this.slug) return
    try {
      await api.widget.enable({ slug: this.slug })
    }
    catch (error) {
      Logger.warning('Widget activating error', false, { slug: this.slug, error })

      // @ts-ignore
      if (error?.origin?.response?.status !== 404) {
        setTimeout(() => {
          this.activateWidget()
        }, 10000)
      }
      else {
        throw new Error(`Incorrect widget slug: ${this.slug}`)
      }
    }
  }

  async fetchWidget (): Promise<void> {
    if (!this.slug) return
    try {
      this.data.value = await api.widget.fetch({ slug: this.slug })
    }
    catch (err) {
      Logger.warning('Widget fetching error', false, { slug: this.slug, err })
    }
  }

  configureRollbar (widget: IWidget) {
    try {
      vm?.$rollbar.configure({
        payload: {
          widget: {
            slug: widget.slug,
            platform: widget.platform,
            enabled: widget.enabled,
            nickname: widget.streamer?.name,
            domain: widget.streamer?.domain,
          },
        },
      })
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error configuring Rollbar')
    }
  }
}
